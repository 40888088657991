import { $, $$ } from './../helpers/qsa'

const ACTIVE_CLASS = 'action-sheet__overlay--active'

$$('.action-sheet').forEach(el => {
    const btn = $('.action-sheet__btn', el)
    const overlay = $('.action-sheet__overlay', el)

    btn.addEventListener('click', () => overlay.classList.add(ACTIVE_CLASS))
    overlay.addEventListener('click', e =>  e.target == overlay && overlay.classList.remove(ACTIVE_CLASS))
})
