import { $, $$ } from './../helpers/qsa'

if (window.matchMedia('(max-width: 1023px)').matches) {
    const navigationLinks = $$('.navigation__container > div > ul > li > a')

    navigationLinks.forEach.call(navigationLinks, (navigationLink) => {
        navigationLink.addEventListener('click', (e) => {
            e.preventDefault()
            navigationLink.classList.toggle('active')
        })
    })
}



const navigationToggles = $$('.navigation__contact-toggle a')

navigationToggles.forEach(navigationToggle => {
    navigationToggle.addEventListener('click', e => {
        e.preventDefault()
        $('.navigation__container').classList.toggle('show-contact')
    })
})

$('.navigation__toggle').addEventListener('click', () => {
    if( $('body').classList.contains('navigation-active') ) {
        setTimeout(() => {
            $('.navigation__container').classList.remove('show-contact')

            navigationLinks.forEach(navigationLink => {
                navigationLink.classList.remove('active')
            })
        }, 350)
    }

    $('body').classList.toggle('navigation-active')
})

const navigtionClose = $('.navigation-close')

navigtionClose.addEventListener('click', function() {
    $('body').classList.remove('navigation-active')
})
