import { $, $$ } from './../helpers/qsa'

const links = Array.from(document.links)

links.forEach(link => {
    if ( link.classList.contains('samepage') ) {
        return
    }

    if ( link.hostname != window.location.hostname || link.href.indexOf('.pdf') !== -1 ) {
        link.setAttribute('target', '_blank')
    }
})
