import { $, $$ } from './../helpers/qsa'
import matchesMedia from './../helpers/matchesMedia'
import Glide from '@glidejs/glide'

const heroCarousel = $('.hero-carousel')

if ( heroCarousel && matchesMedia('(max-width: 768px)') ) {
    const { innerHeight } = window
    $$('.glide__slide', heroCarousel).forEach(item => item.style.height = `${innerHeight}px`)
}

// Set up carousels
const setup = el => {
    const carousel = new Glide(el, {
        type: 'carousel',
        gap: 0,
        autoplay: 100000,
        hoverpause: true
    })
    carousel.mount()
}

$$('.glide').forEach(setup)
