function initInview () {
  const elements = Array.from(document.querySelectorAll('[data-inview]'))

  if (!elements.length) {
    return
  }

  const options = {
    threshold: 0
  }

  elements.forEach(element => observer(options).observe(element))
}

function callback (entries, observer) {
  entries.forEach(entry => {
    const el = entry.target
    if (entry.isIntersecting) {
      el.classList.add(`in-view-visible`)
    }
  })
}

function observer (options) {
  return new window.IntersectionObserver(callback, options)
}

initInview()
