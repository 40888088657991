import { $$ } from './../helpers/qsa'
import createElement from './../helpers/createElement'
import InfiniteScroll from 'infinite-scroll'
import imagesLoaded from 'imagesloaded'
import { IsotopeInstance } from './card-filter'

InfiniteScroll.imagesLoaded = imagesLoaded

let InfiniteScrollInstance
export { InfiniteScrollInstance }

const { CardConfig } = window

$$('.js-infinite-scroll').forEach(el => {
    const status = createElement('div', { innerHTML: `
        <div class="infinite-scroll-request">
            <div class="spinner"></div>
        </div>
    ` })

    el.after(status)

    InfiniteScrollInstance = new InfiniteScroll(el, {
        history: false,
        path: `${CardConfig.INFINITE_BASE_URL}page/{{#}}`,
        append: '.card',
        status: status,
        outlayer: IsotopeInstance
    })

    InfiniteScrollInstance.on( 'append', function( response, path, items ) {
        initInview()
    });
})


function initInview () {
  const elements = Array.from(document.querySelectorAll('[data-inview]'))

  if (!elements.length) {
    return
  }

  const options = {
    threshold: 0
  }

  elements.forEach(element => observer(options).observe(element))
}

function callback (entries, observer) {
  entries.forEach(entry => {
    const el = entry.target
    if (entry.isIntersecting) {
      el.classList.add(`in-view-visible`)
    }
  })
}

function observer (options) {
  return new window.IntersectionObserver(callback, options)
}

