import { $, $$ } from './../helpers/qsa'
import loadJS from 'fg-loadjs'
import styles from './../config/map-styles'

const maps = $$('.location-map')

if (maps.length > 0 && window.MAP_API_KEY) {
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if ( entry.isIntersecting || entry.intersectionRatio > 0 ) {
                window.LOADING_MAP = entry.target
                loadJS(`https://maps.googleapis.com/maps/api/js?key=${window.MAP_API_KEY}&callback=initMap`)
                observer.unobserve(entry.target)
            }
        })
    })

    maps.forEach(map => observer.observe(map))
}

window.initMap = () => {
    const { LOADING_MAP } = window
    const geo = LOADING_MAP.getAttribute('data-location').split(',')

    if (geo.length === 2) {
        let map = new google.maps.Map(LOADING_MAP, {
            center: {
                lat: parseFloat(geo[0]),
                lng: parseFloat(geo[1])
            },
            zoom: 15,
            disableDefaultUI: true,
            styles: styles
        })

        let marker = new google.maps.Marker({
            map: map,
            position: {
                lat: parseFloat(geo[0]),
                lng: parseFloat(geo[1])
            }
        })
    }
}
