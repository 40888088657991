import { $$ } from './../helpers/qsa'
import createElement from './../helpers/createElement'
import { h, app } from 'hyperapp'
import Isotope from 'isotope-layout'

let IsotopeInstance
export { IsotopeInstance }

const { CardConfig } = window

const state = {
    hidden: true,
    activeFilters: [],
}

const actions = {
    setActiveFilters: activeFilters => ({ activeFilters }),
    toggle: () => state => ({ hidden: !state.hidden }),
    clear: () => (state, actions) => {
        actions.setActiveFilters([])
        IsotopeInstance.arrange({
            filter: el => true
        })
    },
    select: values => (state, actions) => {
        const [name, option] = values

        // Create new temporary array
        const filters = Array.from(state.activeFilters)

        // Add or remove element
        const value = `${name}$${option}`
        const index = filters.indexOf(value)
        index < 0 ? filters.push(value) : filters.splice(index, 1)

        // Set to state
        actions.setActiveFilters(filters)

        // Filter
        actions.filter()
    },
    filter: () => state => {
        IsotopeInstance.arrange({
            filter: el => {
                const data = JSON.parse(el.getAttribute('data-filter-data'))

                if ( !data ) {
                    return false
                }

                return state.activeFilters.every(filter => {
                    const [name, option] = filter.split('$')
                    return data[name].includes(option)
                })
            }
        })
    }
}

const view = (state, actions) => (
    <div class="content-filter">
        <div class="content-filter__controls">
            <button onclick={actions.toggle}>{ state.hidden ? 'Show' : 'Hide' } Filters</button>
            <button onclick={actions.clear} class={ state.hidden ? 'hidden' : '' }>Clear Filters</button>
        </div>
        <div class={`content-filter__options  ${state.hidden ? 'hidden' : ''}`}>
            {CardConfig.FILTERS.map(config => <FilterOption {...config}/> )}
        </div>
    </div>
)

const FilterOption = ({ name, options }) => (state, actions) => (
    <div class="content-filter__option">
        <div class="content-filter__option-title">{name}</div>
        <ul class="content-filter__option-list">
            {options.map(option => (
                <li class={state.activeFilters.includes(`${name}$${option}`) ? 'is-active' : ''}
                    onclick={() => actions.select([name, option])}>{option}</li>
                )
            )}
        </ul>
    </div>
)

$$('.js-filterable').forEach(el => {
    // Init Isotope
    IsotopeInstance = new Isotope(el, {
        layoutMode: 'fitRows',
        isJQueryFiltering: false,
        hiddenStyle: {
            opacity: 0
        },
        visibleStyle: {
            opacity: 1
        }
    })

    // Init filters
    const filterContainer = createElement('div', { className: 'card-filter-container' })
    el.before(filterContainer)

    app(state, actions, view, filterContainer)
})
