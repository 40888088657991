// Polyfills
import 'intersection-observer'
import 'whatwg-fetch'

// Vendor
import 'lazysizes'

// App
import './modules/menu'
import './modules/masthead'
import './modules/external-links'
import './modules/map'
import './modules/inview'
import './modules/card-filter'
import './modules/infinite-scroll'
import './modules/action-sheet'
import './modules/carousel'

window.addEventListener('load', () => document.querySelector('.page-content').classList.remove('slide-up'))
