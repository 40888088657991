import { $, $$ } from './../helpers/qsa'

const masthead =  $('.masthead');
const siteCover =  $('.site-cover');

$('.masthead__links__item__search').addEventListener('click', function(e) {
    e.preventDefault()
    masthead.classList.toggle('search-visible')
    masthead.classList.remove('share-visible')
    masthead.classList.remove('copied')
    $('.masthead__popups__search input').focus()
})

$('.masthead__links__item__share').addEventListener('click', function(e) {
    e.preventDefault()
    masthead.classList.toggle('share-visible')
    masthead.classList.remove('search-visible')
    masthead.classList.remove('copied')
})

siteCover.addEventListener('click', function() {
    masthead.classList = 'masthead'
})

const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
    document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
    }
};

const copyLink = $('.masthead__popups__share__item--link');

copyLink.addEventListener('click', function(e) {
    e.preventDefault();
    masthead.classList.add('copied')
    copyToClipboard(location.href)

})





